import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import bsCustomFileInput from "bs-custom-file-input";
import Dropdown from "react-bootstrap/Dropdown";
import { CaretDownFill } from "react-bootstrap-icons"; // Import the down arrow icon
import { FileUploader } from "react-drag-drop-files";

import Logo from "../components/Logo";
import { POST_CONTENT_TYPE, VIDEO_TYPE } from "../constants";
import { getVideoType } from "../helpers/getVideoId";
import { baseURL } from "../constants/baseURL";

const TopNavigationContainer = styled(Navbar)`
  background: #fff;
`;

export default function UploadPage() {
  const history = useHistory();
  const [postImage, setPostImage] = useState();
  const [categories, setCategories] = useState([{ _id: "", label: "" }]);
  const [postData, setPostData] = useState({
    title: "",
    description: "",
    // repoURL: "",
    videoURL: "",
    category: "",
  });

  const draft = JSON.parse(localStorage.getItem("uploadInfo"));

  useEffect(() => {
    document.title = "What are you working on? | Rumbbble";
    async function fetchData() {
      const response = await axios.get(`${baseURL}/categories/all`);
      setCategories([...categories, ...response.data]);
    }
    fetchData();
    bsCustomFileInput.init();
    setImageDraft()
  }, []);

  const setImageDraft = () => {
    if(draft?.file){
      setPostImage(draft.file)
    }
  }

  const handleUpload = (file) => {
    setPostImage(file);
  };

  const handleChange = ({ target: { name, value, file } }) =>
    file ? setPostImage(file) : setPostData({ ...postData, [name]: value });

  const handleSubmit = async () => {
    try {
      const data = new FormData();
      const givenURLType = getVideoType(postData["videoURL"]);
      for (const entry in postData) {
        data.append(entry, postData[entry]);
      }
      data.append("image", postImage);
      if (
        givenURLType === VIDEO_TYPE.YOUTUBE ||
        givenURLType === VIDEO_TYPE.VIMEO
      ) {
        data.append("contentType", POST_CONTENT_TYPE.VIDEO);
      } else {
        data.append("contentType", POST_CONTENT_TYPE.IMAGE);
      }
      await axios.post("/posts", data);
      history.push("/");
    } catch (error) {
      throw Error(error);
    }
  };

  const saveDraft = () => {
    const data = {
      ...postData,
      file: postImage,
    };
    localStorage.setItem("uploadInfo", JSON.stringify(data));
  };

  const renderTopNav = (
    <TopNavigationContainer variant="light">
      <Container fluid>
        <Button
          variant="secondary mr-auto"
          href="/"
          className="d-none d-lg-block rounded-pill bg-red"
          style={{ backgroundColor: "#f7f7f7" }}
        >
          Cancel
        </Button>
        <Row>
          <Button
            variant="secondary"
            style={{ backgroundColor: "#f7f7f7" }}
            className="mx-2 rounded-pill"
            type="submit"
            onClick={saveDraft}
          >
            Save as draft?
          </Button>
          <Button
            onClick={handleSubmit}
            className="ml-auto rounded-pill"
          >
            Publish to Vidfolio
          </Button>
        </Row>
      </Container>
    </TopNavigationContainer>
  );

  const dndChild = (
    <Container>
      <div>
        Drag your drop media, or{" "}
        <span style={{ color: "#E82762" }}>browse</span>{" "}
      </div>
      <span className="small">Maximum uploaded file size: 100MB</span>
    </Container>
  );


  return (
    <Fragment>
      {renderTopNav}
      <Container>
        <Row>
          <Col md={5} className="mx-auto">
            <Form onSubmit={handleSubmit} id="project-submission-form">
              <Form.Group controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  required
                  name="title"
                  autoComplete="off"
                  placeholder="Add a title"
                  defaultValue={draft?.title}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="image">
                {/* <Form.Label>Project Image</Form.Label> */}
                <FileUploader
                  handleChange={handleUpload}
                  name="image"
                  classes="dragndrop"
                  children={dndChild}
                  maxSize={100}
                />
                <p>{postImage ? postImage?.name : null}</p>
                {/* <Form.File
                  custom
                  required
                  label="Project Image"
                  name="image"
                  accept="image/*"
                  onChange={handleChange}
                /> */}
              </Form.Group>
              {/* <Form.Group controlId="repoURL">
                <Form.Label>Repository Link</Form.Label>
                <Form.Control
                  required
                  type="url"
                  name="repoURL"
                  autoComplete="off"
                  onChange={handleChange}
                  defaultValue={draft?.repoURL}
                ></Form.Control>
              </Form.Group> */}
              {/* <Form.Group controlId="demoURL">
                <Form.Label>Demo Link</Form.Label>
                <Form.Control
                  required
                  type="url"
                  name="demoURL"
                  autoComplete="off"
                  onChange={handleChange}
                  defaultValue={draft?.demoURL}
                ></Form.Control>
              </Form.Group> */}
              <Form.Group controlId="videoURL">
                <Form.Label>Youtube Link</Form.Label>
                <Form.Control
                  required
                  type="url"
                  name="videoURL"
                  autoComplete="off"
                  onChange={handleChange}
                  defaultValue={draft?.videoURL}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="videoURL">
                <Form.Label>Vimeo Link</Form.Label>
                <Form.Control
                  required
                  type="url"
                  name="vimeoURL"
                  autoComplete="off"
                  onChange={handleChange}
                  defaultValue={draft?.vimeoURL}
                ></Form.Control>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
