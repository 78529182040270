import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import twitterLogo from '../assets/twitter.png';
import pinterestLogo from '../assets/pinterest.png';

const twitterShareLink = `https://twitter.com/intent/tweet?text=Check%20this%20out%20on%20Vidfolio:%20${window.location.href}`;
const pinterestShareLink = `https://pinterest.com/pin/create/button/?url=${window.location.href}&description=Check%20this%20out%20on%20Vidfolio!`;

function SharePopup({ show, handleClose }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>Share this project</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Button variant="outline-primary" className="mb-2 w-100">
                <img src={twitterLogo} alt="Twitter Logo" width="20" className="mr-2" style={{ marginRight: '0.5rem' }}/>
                Share on Twitter
            </Button>
            <Button variant="outline-primary" className="mb-2 w-100">
                <img src={pinterestLogo} alt="Pinterest Logo" width="20" className="mr-2" style={{ marginRight: '0.5rem' }} />
                Share on Pinterest
            </Button>
            <div className="input-group mb-3">
                <input type="text" className="form-control" style={{ marginRight: '0.5rem', borderRadius: '0.25rem' }} value={window.location.href} readOnly />
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" onClick={() => navigator.clipboard.writeText(window.location.href)}>Copy Link</button>
                </div>
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SharePopup;
