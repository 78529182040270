import React from "react";

export function Skeleton({ numOfSkeletons = 1 }) {
  return (
    <div class="container d-flex">
      {new Array(numOfSkeletons).fill("*").map((skeleton, index) => (
        <div class="col-sm-6 col-md-4">
          <div class="post--isloading">
            <div class="loading-image"></div>
            <div class="loading-content">
              <div class="loading-text-container">
                <div class="loading-main-text"></div>
                <div class="loading-sub-text"></div>
              </div>
              <div class="loading-btn"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
