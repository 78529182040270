import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchQuery } from "../../actions/searchAction";
import styled from "styled-components";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import "./search.css";
import { Skeleton } from "../../components/Skeleton";
import NotFound from "../../assets/notfound.jpg";
import PostPreview from "../../components/PostPreview";
import Introduction from "../../components/Introduction";

const PostPreviewsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 36px;
`;

const SearchMargin = styled.div`
  margin-right: 15px;
`;

const SearchFeed = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();

  const query = props.match.params.searchTerm;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    console.log(searchTerm);
  };

  const { posts, loading, error } = useSelector((state) => state.searchResults);
  // console.log(`Search: ${JSON.stringify(posts)}`);

  useEffect(() => {
    dispatch(searchQuery(query));
  }, []);

  const renderPostPreviews = () => {
    if (Array.isArray(posts)) {
      return posts.map((props) => (
        <PostPreview key={props._id} {...props}>
          <div>Hello</div>
          <Introduction />
        </PostPreview>
      ));
    } else {
      return <div className="query">No posts found.</div>;
    }
  };

  return (
    <div className="container">
      <div className="searchContainer">
        <SearchMargin>
          <InputGroup>
            <FormControl
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon1"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </InputGroup>
        </SearchMargin>
        <Button
          className="ml-2"
          variant="primary"
          href={`/search/${searchTerm}`}
        >
          Search
        </Button>
      </div>
      <div className="searchContainer">
        {posts === undefined ? (
          <p className="query">No posts found. </p>
        ) : (
          <p>
            Outstanding <span className="query">{`${query}`}</span> videos for
            inspiration{" "}
          </p>
        )}
      </div>
      <div className="searchContainer">
        {loading ? (
          <Skeleton numOfSkeletons={3} />
        ) : posts === undefined ? (
          <div className="d-flex justify-content-center">
            <img src={NotFound} className="h-50 w-50" alt="not-found" />
          </div>
        ) : (
          <PostPreviewsContainer className="mt-4 card-deck">
            {renderPostPreviews()}
          </PostPreviewsContainer>
        )}
      </div>
    </div>
  );
};

export default SearchFeed;
