import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";

import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ListGroup from "react-bootstrap/ListGroup";
import Media from "react-bootstrap/Media";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {
  CalendarFill,
  HeartFill,
  GearFill,
  TrashFill,
  FileCode,
  Eye,
  Share,
} from "react-bootstrap-icons";

import CommentFeed from "../components/CommentFeed";
import CommentForm from "../components/CommentForm";
import CommentSection from "../components/CommentSection";

import useNumLikes from "../hooks/useNumLikes";
import useProject from "../hooks/useProject";
import { Link } from "react-router-dom";

import { getVideoType } from "../helpers/getVideoId";
import { VIDEO_TYPE } from "../constants";
import VideoPlayer from "../components/VideoPlayer";
import SharePopup from "../components/SharePopup";

function ProjectPage() {

  const [play, setPlay] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const StyledLink = styled(Link)`
    text-decoration: none;
    color: grey; /* Light grey color */
    font-weight: light; /* Lighter font */
  `;

  const DetailsImageContainer = styled.div`
    /* height:100%; */
    overflow: hidden;
  `;
  const { projectId } = useParams();
  const history = useHistory();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const projectData = useProject(projectId);
  const numLikes = useNumLikes(projectId);

  const { author, title, description, createdAt, videoURL } =
    projectData;

  const handleDelete = async () => {
    try {
      const confirm = window.confirm(
        "Are you sure you want to delete this project?"
      );
      if (!confirm) return;
      await axios.delete(`/posts/${projectId}`);
      history.push("/");
    } catch (error) {
      throw Error(error);
    }
  };

  return (
    <Container>
      <Row>
        <Col xs={12} md={10} className="mx-auto pt-5">
          <Media>
            <Image
              roundedCircle
              width={64}
              height={64}
              className="mr-3"
              src={author?.picture}
              alt="author profile"
            />
            <Media.Body>
              <h5>{title}</h5>
              <div>
                <p>
                  by{" "}
                  <StyledLink to={`/channel/${author?._id}`}>
                    {author?.name}
                  </StyledLink>
                </p>
              </div>
            </Media.Body>
          </Media>
          <DetailsImageContainer>
            {/* <Image
              fluid
              rounded
              className="object-fit-fill py-3 mx-auto"
              src={projectData?.image}
            /> */}
            <VideoPlayer videoURL={videoURL} play={play} />
          </DetailsImageContainer>
          <Row>
            <Col md={8}>
              <h5 className="mt-3">Description: </h5>
              {description}
              <hr />
              <CommentSection projectId={projectId} />
            </Col>
            
            <Col md={4} className="mb-4">
              <ButtonToolbar
                className="mb-3 mt-2"
                aria-label="Toolbar with button groups"
              >
                <ButtonGroup
                  size="sm"
                  className="flex-grow-1"
                  aria-label="Second group"
                >
                  <Button variant="light" href={videoURL}>
                    <FileCode className="mr-2" size={15} style={{ marginRight: '0.5rem' }}/>
                    {getVideoType(videoURL) === VIDEO_TYPE.YOUTUBE
                      ? "View Youtube"
                      : getVideoType(videoURL) === VIDEO_TYPE.VIMEO
                      ? "View Vimeo"
                      : "View Demo"}
                  </Button>
                </ButtonGroup>
                <ButtonGroup
                  size="sm"
                  className="mr-2 flex-grow-1"
                  aria-label="First group"
                >
                  <Button variant="light" onClick={() => setShowPopup(true)}><Share className="mr-2" style={{ marginRight: '0.5rem' }}size={15}/>
                    Share</Button>
                </ButtonGroup>
                
              </ButtonToolbar>
              <ListGroup className="mb-4" variant="flush">
                <ListGroup.Item>
                  <HeartFill className="mr-3" style={{ marginRight: '0.5rem' }} />
                  {numLikes} like{numLikes !== 1 ? "s" : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <CalendarFill className="mr-3" style={{ marginRight: '0.5rem' }}/>
                  {moment(createdAt).format("MMM D, YYYY")}
                </ListGroup.Item>
              </ListGroup>
              {/* {user?._id === author?._id && ( */}
              <ListGroup variant="flush">
                <ListGroup.Item action href={`/projects/${projectId}/edit`}>
                  <GearFill className="mr-3" style={{ marginRight: '0.5rem' }}/>
                  Edit
                </ListGroup.Item>
                <ListGroup.Item action onClick={handleDelete}>
                  <TrashFill className="mr-3" style={{ marginRight: '0.5rem' }}/>
                  Delete
                </ListGroup.Item>
              </ListGroup>
              {/* )} */}
            </Col>
          </Row>
        </Col>
      </Row>
      <SharePopup show={showPopup} handleClose={() => setShowPopup(false)} />
    </Container>
    
  );
}

export default ProjectPage;
