import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styled from "styled-components";

import useProject from "../hooks/useProject";

const TopNavigationContainer = styled(Navbar)`
  background: #0d0c22;
`;

const LogoContainer = styled(Navbar.Brand)`
  line-height: 0;
`;

const Logo = styled(Image)`
  width: 6rem;
  height: auto;
`;

export default function EditPage() {
  const { projectId } = useParams();
  const history = useHistory();
  const projectData = useProject(projectId);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    videoURL: "",
  });

  const { title, description, repoURL, videoURL } = formData;

  useEffect(() => {
    if (Object.keys(projectData).length === 0) return;
    const { title, description, videoURL } = projectData;
    setFormData({ title, description, videoURL });
  }, [projectData]);

  const handleChange = ({ target: { name, value, files } }) =>
    setFormData({ ...formData, [name]: value });

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      await axios.patch(`/posts/${projectId}`, formData);
      history.push(`/projects/${projectId}`);
    } catch (error) {
      throw Error(error);
    }
  };

  const renderTopNav = (
    <TopNavigationContainer>
      <Container>
        <LogoContainer href="/">
          {/* <Logo src={require("../assets/logo.png")} alt="logo" /> */}
        </LogoContainer>
        <Navbar.Text className="font-weight-bold text-white mx-auto">
          Publish your Project
        </Navbar.Text>
        <Navbar.Text>Help</Navbar.Text>
      </Container>
    </TopNavigationContainer>
  );

  const renderBottomNav = (
    <Navbar sticky="bottom" className="border-top">
      <Container>
        <Button variant="outline-secondary">Cancel</Button>
      </Container>
    </Navbar>
  );

  return (
    <Fragment>
      <Navbar>
        <Container>
          <Navbar.Brand href="/">Vidfolio</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text className="mr-3">Publish your Project</Navbar.Text>
            <Navbar.Text>Help</Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      
      <Container className="mt-5">
        <Row>
          <Col md={8} className="mx-auto">
            <p>Project Image</p>
            <Image fluid src={`${window.location.origin}/${projectData.image}`} alt="Missing" />

            <Form onSubmit={handleSubmit} className="mt-4">
              <Form.Group controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Add a title"
                  name="title"
                  value={title}
                  onChange={handleChange}
                  required
                  className="mb-3"
                />
              </Form.Group>
              
              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={description}
                  onChange={handleChange}
                  required
                  className="mb-3"
                />
              </Form.Group>

              <Form.Group controlId="videoURL">
                <Form.Label>Youtube Video URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter YouTube video URL"
                  name="videoURL"
                  value={videoURL}
                  onChange={handleChange}
                  required
                  className="mb-3"
                />
              </Form.Group>

              <Button variant="primary" type="submit">Submit</Button>
            </Form>
          </Col>
        </Row>
      </Container>

      <Navbar fixed="bottom" className="border-top py-3">
        <Container>
          <Button variant="outline-secondary" onClick={() => history.push('/')}>Cancel</Button>
        </Container>
      </Navbar>
    </Fragment>
  );
}