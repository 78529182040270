import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { Search } from 'react-bootstrap-icons'; // Importing the search icon
import profileImage from "../../assets/Profile.png";

import Logo from "../Logo";
import { logout } from "../../actions/userAction";

const NavigationContainer = styled(Navbar)`
  background:"#333";
  color: "#333";
`;

const SearchMargin = styled.div`
  margin-right: 15px;
`;

const AboutMargin = styled(Nav.Link)`
  margin-right: 10px;
`;


const StyledFormControl = styled(FormControl)`
  padding-left: 2.5rem !important; // Adjust this value to position the placeholder text appropriately
`;

const SearchIconInsideInput = styled.div`
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    z-index: 2;
  }

  input {
    padding-left: 40px; /* Adjust based on the size of the icon */
  }
`;

export default function Navigation() {
  const [searchTerm, setSearchTerm] = useState("");
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    console.log(searchTerm);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    // Here you can navigate to the search page or handle the search logic
    window.location.href = `/search/${searchTerm}`;
  };


  function logoutUser() {
    dispatch(logout());
    // alert.success("Logout Successfully");
  }

  const renderLoggedIn = (
    <Fragment>
      <Nav.Link href="/">Inspiration</Nav.Link>
      <AboutMargin href="/about">About</AboutMargin>
      <SearchMargin>
        <form onSubmit={handleSearchSubmit}>
          <InputGroup>
            <SearchIconInsideInput>
              <Search />
              <StyledFormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </SearchIconInsideInput>
          </InputGroup>
        </form>
      </SearchMargin>
      <Button className="ml-2" variant="secondary" href={`/search/${searchTerm}`}>
        Search
      </Button>
      <NavDropdown
        id="profile-dropdown"
        title={
          <Image src={profileImage} height="40" width="40" roundedCircle />
        }
      >
        <NavDropdown.Item onClick={() => logoutUser()}>
          Sign Out
        </NavDropdown.Item>
        <NavDropdown.Item href={`/channel/${user?._id}`}>
          Profile
        </NavDropdown.Item>
      </NavDropdown>
      <Button className="ml-2" variant="primary" href="/projects/new">
        Upload
      </Button>
    </Fragment>
  );

  const renderLoggedOut = (
    <Fragment>
      <Nav.Link href="/">Inspiration</Nav.Link>
      <AboutMargin href="/about">About</AboutMargin>
      <SearchMargin>
        <form onSubmit={handleSearchSubmit}>
          <InputGroup>
            <SearchIconInsideInput>
              <Search />
              <StyledFormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </SearchIconInsideInput>
          </InputGroup>
        </form>
      </SearchMargin>

      <Button className="" variant="secondary" href={`/search/${searchTerm}`}>
        Search
      </Button>
      <Nav.Link href="/login">Sign in</Nav.Link>
      <Button
        className="d-none d-lg-block d-xl-block ml-3"
        variant="primary"
        href="/register"
      >
        Sign up
      </Button>

    </Fragment>
  );

  return (
    <NavigationContainer  sticky="top">
      <Container>
        <Navbar.Brand className="mr-auto" href="/">
          <Logo />
        </Navbar.Brand>
        <Nav className="align-items-center">
          
          {isAuthenticated ? renderLoggedIn : renderLoggedOut}
        </Nav>
      </Container>
    </NavigationContainer>
  );
}
