import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import { baseURL } from "../constants/baseURL";

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 20px;
`;

export default function Filter({ selectedCategory, handleCategorySelect }) {
  const [categories, setCategories] = useState([{ _id: "0", label: "ALL" }]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        `${baseURL}/categories/all`
      );
      setCategories([...categories, ...data]);
    }
    fetchData();
  }, []);
  return (
    <FilterContainer>
      {categories.map((category, index) => (
        <Button
          key={index}
          variant={category._id === selectedCategory ? "primary" : "secondary"}
          onClick={() => handleCategorySelect(category)}
          style={{ marginRight: "5px", marginBottom: "5px" }}
        >
          {category.label}
        </Button>
      ))}
    </FilterContainer>
  );
}
