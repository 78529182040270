import React, { useEffect, useRef } from "react";
import YouTube from "react-youtube";
import Vimeo from "@u-wave/react-vimeo";

import { getVideoType, getVideoId } from "../helpers/getVideoId";
import styled from "styled-components";
import { VIDEO_TYPE } from "../constants";
import ".././styles.css";

// const VideoContainer = styled.div`
//   border-radius: 8px;
//   height: 40vw;
//   min-height: 270px;
//   object-fit: cover;
//   overflow: hidden;
// `;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
  border-radius: 8px;
`;

const StyledYouTube = styled(YouTube)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const StyledVimeo = styled(Vimeo)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

function VideoPlayer({ videoURL, play = true }) {
  const videoRef = useRef(null);
  const videoType = getVideoType(videoURL);
  let videoId = getVideoId(videoURL);
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0,
      controls: 1,
    },
  };

//   useEffect(() => {
//     if (videoRef?.current) {
//       if (play) handleMouseOver();
//       else handleMouseOut();
//     }
//   }, [play, videoRef]);

//   const handleMouseOver = async () => {
//     await videoRef?.current?.internalPlayer?.mute();
//     await videoRef?.current?.internalPlayer?.playVideo();
//   };

//   const handleMouseOut = async () => {
//     await videoRef?.current?.internalPlayer?.pauseVideo();
//   };

  function _onReady(e) {
    e.target.pauseVideo();
  }

  function handleError(e) {
    console.log(e);
  }

  if (videoType === VIDEO_TYPE.YOUTUBE)
    return (
      <VideoContainer>
        <StyledYouTube
          ref={videoRef}
          videoId={videoId}
          opts={opts}
          onReady={_onReady}
          title={""}
          style={{ height: "100%" }}
        />
        ;
      </VideoContainer>
    );

  if (videoType === VIDEO_TYPE.VIMEO)
    return (
      <VideoContainer>
        <StyledVimeo
          video={videoId}
          muted={true}
          paused={!play}
          controls={true}
          onError={handleError}
          className="vimeoContainer"
          style={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
            objectFit: "cover",
            backgroundColor: "black",
            padding: "0px",
          }}
          height="600px"

        />
      </VideoContainer>
    );
  return <div> </div>;
}

export default VideoPlayer;
