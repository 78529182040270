import React, { useState } from 'react';
import styled from 'styled-components';

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 20px;
`;

const categories = ['Work', 'Projects', 'Collections', 'Liked Shots', 'About'];

const StyledButton = styled.button`
  background: ${({ selected }) => (selected ? '#333333' : '#FFFFFF')};
  color: ${({ selected }) => (selected ? '#FFFFFF' : '#333333')};
  border: 1px solid #E7E7E9;
  border-radius: 100px;
  width: 126px;
  height: 46px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
`;

export default function ProfileFilter({ selectedCategory, handleCategorySelect }) {
  const [selected, setSelected] = useState(selectedCategory);

  const handleButtonClick = (category) => {
    setSelected(category);
    handleCategorySelect(category);
  };

  return (
    <FilterContainer>
      {categories.map((category, index) => (
        <StyledButton
          key={index}
          selected={selected === category}
          onClick={() => handleButtonClick(category)}
        >
          {category}
        </StyledButton>
      ))}
    </FilterContainer>
  );
}
