import React from "react";
import styled from "styled-components";
import LogoPNG from "../assets/logo.png"; // Import the PNG logo

const LogoContainer = styled.img`
  width: 6rem;
  height: auto;
`;

export default function Logo({ color = "#0d0c22", ...restProps }) {
  return <LogoContainer src={LogoPNG} alt="Logo" {...restProps} />;
}
