import { INVALID_VIDEO_ID, VIDEO_TYPE } from "../constants";

export function getVideoId(url) {
  if (!url) return INVALID_VIDEO_ID;
  if (getVideoType(url) === VIDEO_TYPE.YOUTUBE) {
    let regex =
      /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
    var allMatches = regex.exec(url);
    return allMatches?.[3];
  }
  if (getVideoType(url) === VIDEO_TYPE.VIMEO) {
    const regex = /vimeo.*\/(\d+)/i;
    const matches = regex.exec(url);
    if (matches) {
      return matches[1];
    }
  }
  return INVALID_VIDEO_ID;
}

export function getVideoType(url) {
  // Regular expressions for matching YouTube and Vimeo URLs
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
  const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/;

  if (youtubeRegex.test(url)) {
    return VIDEO_TYPE.YOUTUBE;
  } else if (vimeoRegex.test(url)) {
    return VIDEO_TYPE.VIMEO;
  } else {
    return VIDEO_TYPE.OTHER;
  }
}
