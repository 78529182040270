import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "./app/store";
import PrivateRoute from "./components/PrivateRoute";
import LandingPage from "./pages/Landing";
import LoginPage from "./pages/Login/Login";
import ForgotPassword from "./components/user/ForgotPassword";
import ResetPassword from "./components/user/ResetPassword";
import ProjectPage from "./pages/Project";
import UploadPage from "./pages/Upload";
import EditPage from "./pages/Edit";
import AboutPage from "./pages/About";
import AuthorChannel from "./components/AuthorChannel";
import Navigation from "./components/Home/Navigation";
import Footer from "./components/Footer";
import { loadUser } from "./actions/userAction";
import SearchFeed from "./pages/search/SearchFeed";

function App() {
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if (isAuthenticated) {
      store.dispatch(loadUser());
    }
  }, []);

  return (
    <BrowserRouter>
      <Navigation />
      <Switch>
        {/* <Route exact path="/feed" component={Feed} />
        <Route exact path="/video/:id" component={VideoDetail}/>
        <Route exact path="/channel/:id" component={ChannelDetail}/>*/}
        <Route exact path="/search/:searchTerm" component={SearchFeed} />
        <Route exact path="/" component={LandingPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/register" component={LoginPage} />
        <Route exact path="/password/forgot" component={ForgotPassword} />
        <Route exact path="/password/reset/:token" component={ResetPassword} />
        <PrivateRoute exact path="/projects/new" component={UploadPage} />
        <Route exact path="/projects/:projectId" component={ProjectPage} />
        <Route path="/about" component={AboutPage} />{" "}
        {/* <-- New Route for AboutPage */}
        <Route path="/channel/:authorId" component={AuthorChannel} />
        <PrivateRoute
          exact
          path="/projects/:projectId/edit"
          component={EditPage}
        />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
