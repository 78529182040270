import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import styled from 'styled-components';
import logo from "../assets/logo.png"; // Import the PNG logo
import twitter from "../assets/twitter.png"; // Import the PNG logo
import facebook from "../assets/facebook.png"; // Import the PNG logo
import linkedin from "../assets/linkedin.png"; // Import the PNG logo
import pinterest from "../assets/pinterest.png"; // Import the PNG logo
import instagram from "../assets/instagram.png"; // Import the PNG logo
import language from "../assets/language.png"; // Import the PNG logo
import coin from "../assets/coin.png"; // Import the PNG logo


const StyledLink = styled(Link)`
  text-decoration: none;
  color: grey;  /* Light grey color */
  font-weight: light; /* Lighter font */
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  color: #666;
  margin: 50px 0px;
`;

const FooterInnerContainer = styled.div`
  width: 1400px;
  
  img {
    height: 24px;
    width: 24px;
  }

  .logo {
    width: 50px; // Adjust to make logo smaller
    height: auto;
  }

  .top, .bottom {
    display: flex;
    justify-content: space-between;

    .item {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h2 {
        font-size: 16px;
        color: #555;
      }

      span {
        font-weight: 300;
      }
    }

    hr {
      margin: 50px 0px;
      height: 0;
      border: 1px solid #ebe9e9;
    }
  }

  .bottom {
    align-items: center;

    .left, .right {
      width: max-content;
      display: flex;
      align-items: center;
      gap: 20px;

      span {
        font-size: 13px;
        white-space: nowrap;
      }
    }

    .right {
      gap: 30px;

      .social, .link {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterInnerContainer className="container">
        {/* <div className="top">
          <div className="item">
            <h2>Categories</h2>
            
          </div>
          <div className="item">
            <h2>About</h2>
            <StyledLink to="/about">Learn More</StyledLink>  {/* <-- Link to the About page */}
          {/* </div>
          <div className="item">
            <h2>Support</h2>
            
          </div>
          <div className="item">
            <h2>Community</h2>
            
          </div>
          <div className="item">
            <h2>Feature Request</h2>
            
          </div>
        </div> */}
        <hr />
        <div className="bottom">
        <div className="left">
            <img src={logo} alt="Liverr Logo" className="logo" />
            <span>© Vidfolio International Ltd. 2023</span>
          </div>
          <div className="right">
            <div className="social">
              <img src={twitter} alt="" />
              <img src={facebook} alt="" />
              <img src={linkedin} alt="" />
              <img src={pinterest} alt="" />
              <img src={instagram} alt="" />
            </div>
            <div className="link">
              <img src={language} alt="" />
              <span>English</span>
            </div>
            
            {/* <img src="/img/accessibility.png" alt="" /> */}
          </div>
        </div>
      </FooterInnerContainer>
    </FooterContainer>
  );
}

export default Footer;
