export const INVALID_VIDEO_ID="idCgctR1d0";

export const POST_CONTENT_TYPE = {
  VIDEO: "video",
  IMAGE: "image",
  GIF: "gif",
};

export const VIDEO_TYPE = {
  YOUTUBE: "youtube",
  VIMEO: "vimeo",
  OTHER: "other",
  NONE: "none",
};
