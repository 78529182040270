import {
  SEARCH_REQUEST,
  SEARCH_FAIL,
  SEARCH_SUCCESS,
} from "../constants/searchConstants";
import axios from "axios";
import { baseURL } from "../constants/baseURL";

// Search
export const searchQuery = (query) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.get(
      `${baseURL}/posts/search?query=${query}`,
      config
    );
    dispatch({ type: SEARCH_SUCCESS, payload: data.posts });
  } catch (error) {
    dispatch({ type: SEARCH_FAIL, payload: error.response.data.message });
  }
};
