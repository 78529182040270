import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import styled from "styled-components";
import Container from "react-bootstrap/Container";

import Filter from "../components/Filter"; // Import the Filter component
import DropdownFilter from "../components/DropdownFilter"; // Import the DropdownFilter component
import Introduction from "../components/Introduction";
import PostPreview from "../components/PostPreview";
import { Skeleton } from "../components/Skeleton";
import NotFound from "../assets/notfound.jpg";
import { baseURL } from "../constants/baseURL";

const PostPreviewsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 36px;
`;

const RightFilterContainer = styled.div`
  margin-top: 17.5px; // Drop down about 10px
`;
function LandingPage() {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [projectFeed, setProjectFeed] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("0"); // New state for selected category
  const [selectedSort, setSelectedSort] = useState("Relevant"); // New state for selected sort

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    const fetchProjecFeed = async () => {
      const { data: feed } = await axios.get(`${baseURL}/posts/all`, {
        params: { categoryId: selectedCategory },
      });
      setLoading(false);
      if (mounted) setProjectFeed(feed);
    };
    fetchProjecFeed();
    return () => (mounted = false);
  }, [selectedCategory]);

  useEffect(() => {
    document.title =
      "Vidfolio - Discover the World's Top Developers and Programming Enthusiasts";
  }, []);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category._id);
  };

  const handleSortSelect = (sortMethod) => {
    // New function to handle sort selection
    setSelectedSort(sortMethod);
    // Add logic to sort the posts based on the selected method
  };

  const renderPostPreviews = () =>
    projectFeed.map((props) => (
      <PostPreview key={props._id} {...props}>
        <div>Hello</div>
        <Introduction />
      </PostPreview>
    ));
  return (
    <Fragment>
      {!user && <Introduction />}
      <Container className="px-sm-0">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DropdownFilter // New DropdownFilter component
            selectedSort={selectedSort}
            handleSortSelect={handleSortSelect}
          />
          <RightFilterContainer>
            <Filter
              selectedCategory={selectedCategory}
              handleCategorySelect={handleCategorySelect}
            />
          </RightFilterContainer>
        </div>

        {loading ? (
          <Skeleton numOfSkeletons={3} />
        ) : projectFeed.length === 0 ? (
          <div className="d-flex justify-content-center">
            <img src={NotFound} className="h-50 w-50" alt="not-found" />
          </div>
        ) : (
          <PostPreviewsContainer className="mt-4 card-deck">
            {renderPostPreviews()}
          </PostPreviewsContainer>
        )}
      </Container>
    </Fragment>
  );
}

export default LandingPage;
