import React, { Fragment, useRef, useState, useEffect } from "react";
import "./styles.css";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login, register } from "../../actions/userAction";
import { useHistory } from "react-router-dom";

import GoogleImage from "../../assets/google.png";
import FacebookImage from "../../assets/facebook01.png";
import AppleImage from "../../assets/apple.png";

export default function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );

  const loginTab = useRef(null);
  const registerTab = useRef(null);
  const switcherTab = useRef(null);

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [errorr, setError] = useState("");

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
  });

  const { name, email, password } = user;

  const loginSubmit = (e) => {
    e.preventDefault();
    console.log(loginEmail, loginPassword);
    dispatch(login(loginEmail, loginPassword));
  };

  const registerSubmit = (e) => {
    e.preventDefault();
    dispatch(register(name, email, password));
  };

  const registerDataChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
      setError(error);
    }

    if (isAuthenticated) {
      history.push("/");
    }

    // Use history.location.state to determine which tab to display
    if (history.location.pathname === "/login") {
      switchTabs(null, "login");
    } else if (history.location.pathname === "/register") {
      switchTabs(null, "register");
    }
  }, [dispatch, history, isAuthenticated]);

  const switchTabs = (e, tab) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (tab === "login") {
      switcherTab.current.classList.add("shiftToNeutral");
      switcherTab.current.classList.remove("shiftToRight");
      registerTab.current.classList.remove("shiftToNeutralForm");
      loginTab.current.classList.remove("shiftToLeft");
      history.push("/login"); // Navigate to /login URL
    }

    if (tab === "register") {
      switcherTab.current.classList.add("shiftToRight");
      switcherTab.current.classList.remove("shiftToNeutral");
      registerTab.current.classList.add("shiftToNeutralForm");
      loginTab.current.classList.add("shiftToLeft");
      history.push("/register"); // Navigate to /register URL
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="LoginSignUpContainer">
            <div className="LoginSignUpBox">
              <div className="login_signUp_toggle_container">
                <div className="login_signUp_toggle">
                  <h4 onClick={(e) => switchTabs(e, "login")}>Login</h4>
                  <h4 onClick={(e) => switchTabs(e, "register")}>Register</h4>
                </div>
                <button ref={switcherTab}></button>
              </div>

              <div className="contentContainer" ref={loginTab}>
                <div className="login_heading">
                  <h2>Welcome back to Vidfolio</h2>
                </div>
                <div className="section">
                  <p>Sign in with email</p>
                  <form onSubmit={loginSubmit}>
                    <div className="input_field_main">
                      <label>Email:</label>
                      <input
                        type="email"
                        placeholder="Email"
                        required
                        value={loginEmail}
                        onChange={(e) => setLoginEmail(e.target.value)}
                        style={{
                          border: "1px solid lightgray",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                    <div className="input_field_main">
                      <label>Password:</label>
                      <input
                        type="password"
                        placeholder="Password"
                        required
                        value={loginPassword}
                        onChange={(e) => setLoginPassword(e.target.value)}
                        style={{
                          border: "1px solid lightgray",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                    <div className="login_btn-mian">
                      <button type="submit" className="login_btn">
                        Log in
                      </button>
                    </div>
                  </form>
                  <div className="or-separator">
                    <span className="or-text">or</span>
                  </div>
                  <button className="social_login">
                    <img src={GoogleImage} alt="Image" />
                    Continue with Google
                  </button>
                  <button className="social_login">
                    <img src={FacebookImage} alt="Image" />
                    Continue with Facebook
                  </button>
                  <button className="social_login">
                    <img src={AppleImage} alt="Image" />
                    Continue with Apple
                  </button>
                </div>
              </div>
              <div className="contentContainer" ref={registerTab}>
                <div className="signup_heading">
                  <h2>Create an account</h2>
                </div>
                <div className="section">
                  <p className="signup_heading">Sign up with email</p>
                  <form onSubmit={registerSubmit}>
                    <div className="input_field_main">
                      <label>Name:</label>
                      <input
                        type="text"
                        placeholder="Name"
                        required
                        name="name"
                        value={name}
                        onChange={registerDataChange}
                        style={{
                          border: "1px solid lightgray",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                    <div className="input_field_main">
                      <label>Email:</label>
                      <input
                        type="email"
                        placeholder="Email"
                        required
                        name="email"
                        value={email}
                        onChange={registerDataChange}
                        style={{
                          border: "1px solid lightgray",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                    <div className="input_field_main">
                      <label>Password:</label>
                      <input
                        type="password"
                        placeholder="Password"
                        required
                        name="password"
                        value={password}
                        onChange={registerDataChange}
                        style={{
                          border: "1px solid lightgray",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                    <div className="signup_btn-mian">
                      <button type="submit" className="signup_btn">
                        Sign Up
                      </button>
                    </div>
                  </form>
                  <div className="or-separator">
                    <span className="or-text">or</span>
                  </div>
                  <button className="social_login">
                    <img src={GoogleImage} alt="Image" />
                    Continue with Google
                  </button>
                  <button className="social_login">
                    <img src={FacebookImage} alt="Image" />
                    Continue with Facebook
                  </button>
                  <button className="social_login">
                    <img src={AppleImage} alt="Image" />
                    Continue with Apple
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
