import {
  SEARCH_REQUEST,
  SEARCH_FAIL,
  SEARCH_SUCCESS,
} from "../constants/searchConstants";

const initialState = {
  loading: false,
  posts: [],
  error: null,
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null, // Reset error when making a new request
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        posts: action.payload,
        error: null, // Reset error on success
      };
    case SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
