import React from 'react';
import styled from 'styled-components';
import cinema from "../assets/cinema1.jpg";
import video from "../assets/video1.jpg";
import hiring from "../assets/hiring1.jpg";
import team from "../assets/team1.jpg";

const fadeIn = `
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  animation: fadeIn 1s forwards;
`;

const AboutContainer = styled.div`
  padding: 50px;
`;

const IntroSection = styled.section`
  text-align: center;
  margin-bottom: 50px;
`;

const ValuesSection = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
`;

const ValueCard = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;
  ${fadeIn}
`;

const StyledImage = styled.img`
  width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const TeamImage = styled.img`
  width: 300px;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const TeamSection = styled.section`
  text-align: center;
  margin-bottom: 50px;
`;

const TestimonialsSection = styled.section`
  text-align: center;
  margin-bottom: 50px;
`;

function AboutPage() {
  return (
    <AboutContainer>
      <IntroSection>
        <h1>About Vidfolio</h1>
        <p>
          Vidfolio is the world’s leading community for video editors to share, 
          grow, and get hired.
        </p>
      </IntroSection>

      <ValuesSection>
        <ValueCard>
          <StyledImage src={video} alt="For Video Editors" />
          <h3>For Video Editors</h3>
          <p>Host your video portfolio to showcase your talents.</p>
        </ValueCard>
        <ValueCard>
          <StyledImage src={hiring} alt="For Hiring Managers" />
          <h3>For Hiring Managers</h3>
          <p>Post your video editing job for hire on Vidfolio and hire the best talent.</p>
        </ValueCard>
        <ValueCard>
          <StyledImage src={cinema} alt="For Enthusiasts" />
          <h3>For Enthusiasts</h3>
          <p>For you! Explore video editing work, and find the best editors.</p>
        </ValueCard>
      </ValuesSection>

      <TeamSection>
        <h2>Meet the Team</h2>
        <p>We are a group of passionate individuals striving to make hiring easier and more fair for video editors.</p>
        <TeamImage src={team} alt="Team" />
      </TeamSection>

      <TestimonialsSection>
        <h2>What People Are Saying</h2>
        <p>
          "Vidfolio has drastically changed the way I showcase my work and connect with clients."
        </p>
        <p>- Danielle Lopez, Video Editor</p>
      </TestimonialsSection>
    </AboutContainer>
  );
}

export default AboutPage;
