import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { CaretDownFill } from 'react-bootstrap-icons';  // Import the down arrow icon

const DropdownFilter = ({ selectedSort, handleSortSelect }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="tertiary" id="dropdown-basic">
        {selectedSort} <CaretDownFill/>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleSortSelect('Popular')}>Popular</Dropdown.Item>
        <Dropdown.Item onClick={() => handleSortSelect('New')}>New</Dropdown.Item>
        <Dropdown.Item onClick={() => handleSortSelect('Following')}>Following</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownFilter;
